<template>
  <div class="d-flex justify-end">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          :disabled="tokenLock.type === 2 || tokenLock.owner.toLowerCase() !== account"
        >
          <v-icon small color="grey">mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-list class="text-left">
        <v-list-item
          v-if="tokenLock.type === 1 || (tokenLock.startEmission > 0 && tokenLock.startEmission < Date.now() / 1000)"
        >
          <v-list-item-title style="cursor: pointer" @click="withdrawState = !withdrawState">
            Withdraw</v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title style="cursor: pointer" @click="relockState = !relockState">Relock</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <WithdrawTokenDialog :state="withdrawState" :tokenLock="tokenLock" @withdrawDone="$emit('withdrawDone')" />
    <RelockTokenDialog :state="relockState" :tokenLock="tokenLock" @relockDone="$emit('relockDone')" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WithdrawTokenDialog from '@/components/dialogs/tokenLocks/WithdrawTokenDialog'
import RelockTokenDialog from '@/components/dialogs/tokenLocks/RelockTokenDialog'
export default {
  components: { WithdrawTokenDialog, RelockTokenDialog },
  props: {
    tokenLock: Object,
  },
  computed: {
    ...mapState('auth', ['account']),
  },
  data() {
    return {
      withdrawState: false,
      relockState: false,
    }
  },
}
</script>
