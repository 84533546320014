<template>
  <v-sheet rounded="xl" class="pa-6" :width="$vuetify.breakpoint.lgAndUp ? '850px' : ''">
    <div class="mb-6 text-left text-medium">
      <v-icon class="mr-2" large @click="$router.go(-1)">mdi-arrow-left</v-icon> All locks for {{ userTokenInfo.name }}
    </div>
    <div class="d-flex justify-end text-start">
      <v-menu :close-on-content-click="false" offset-y class="white--text">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text @click.stop>
            Filter: {{ lockChecked ? 'Lock' : '' }} {{ unlockChecked ? 'Unlock' : '' }}
            {{ withdrawnChecked ? 'Withdrawn' : '' }}
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        <v-container fluid class="pt-0">
          <v-checkbox v-model="lockChecked" hide-details label="Lock" max-height="24" @change="checkboxUpdated" />
          <v-checkbox v-model="unlockChecked" hide-details label="Unlock" max-height="24" @change="checkboxUpdated" />
          <v-checkbox
            v-model="withdrawnChecked"
            hide-details
            label="Withdrawn"
            max-height="24"
            @change="checkboxUpdated"
          />
        </v-container>
      </v-menu>
    </div>
    <v-data-table
      :loading="loading"
      :items="filteredTokenLocks"
      items-key="id"
      :headers="originalHeaders"
      class="myTable"
    >
      <template v-slot:[`item.amount`]="{ item }"
        ><div class="d-flex align-center">
          <v-img
            max-width="24"
            max-height="24"
            contain
            :src="
              item.type === 0
                ? require(`@/assets/lock.svg`)
                : item.type === 1
                ? require(`@/assets/unlock.svg`)
                : require(`@/assets/withdrawn.svg`)
            "
          />
          &nbsp;
          {{ item.type === 2 ? item.tokensDeposited : item.tokensDeposited - item.tokensWithdrawn }}
        </div></template
      >
      <template v-slot:[`item.owner`]="{ item }">{{ item.owner | getAddress(account) }} </template>
      <template v-slot:[`item.lockCreator`]="{ item }">{{ item.lockCreator | getAddress(account) }} </template>
      <template v-slot:[`item.endEmission`]="{ item }">
        {{ item.endEmission | yyyymmdd }}
      </template>
      <template v-slot:[`item.unlockDate`]="{ item }">
        {{ item.endEmission | normalizeTimeDuration }}. {{ item.endEmission | yyyymmdd }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <LockListActions :tokenLock="item" @relockDone="fetchData" @withdrawDone="fetchData" />
      </template>
    </v-data-table>
  </v-sheet>
</template>
<script>
import LockListActions from '@/modules/tokenLocks/LockListActions'
import { mapState, mapActions, mapGetters } from 'vuex'
const originalHeaders = [
  {
    text: 'Amount',
    align: 'left',
    sortable: true,
    value: 'amount',
  },
  {
    text: 'Owner',
    align: 'left',
    sortable: false,
    value: 'lockCreator',
  },
  {
    text: 'Withdrawer',
    align: 'left',
    sortable: false,
    value: 'owner',
  },
  {
    text: 'Lock date',
    align: 'left',
    sortable: true,
    value: 'endEmission',
  },
  {
    text: 'Unlock Date',
    align: 'left',
    sortable: true,
    value: 'unlockDate',
  },
  {
    align: 'left',
    sortable: false,
    value: 'action',
  },
]
export default {
  components: {
    LockListActions,
  },
  computed: {
    ...mapState('auth', ['lockHandler', 'account']),
    ...mapState('tokenLocks', ['tokenLocks', 'lockFilter', 'userTokenInfo']),
    ...mapGetters('tokenLocks', ['filteredTokenLocks']),
    getFilter() {
      return { lock: this.lockChecked, unlock: this.unlockChecked, withdrawn: this.withdrawnChecked }
    },
  },

  data() {
    return {
      originalHeaders,
      relockState: false,
      lockChecked: true,
      unlockChecked: false,
      withdrawnChecked: false,
      loading: false,
      tokenAddress: '',
    }
  },
  methods: {
    ...mapActions('tokenLocks', ['fetchTokenLocks', 'setLockFilter']),
    checkboxUpdated() {
      const filter = { lock: this.lockChecked, unlock: this.unlockChecked, withdrawn: this.withdrawnChecked }
      this.setLockFilter(filter)
    },
    async fetchData() {
      try {
        this.loading = true
        await this.fetchTokenLocks({ tokenAddress: this.tokenAddress, lockHandler: this.lockHandler })
      } catch (e) {
        this.$alert.error(e.message)
      } finally {
        this.loading = false
      }
    },
  },
  async created() {
    this.tokenAddress = this.$route.params.tokenAddr
    try {
      this.loading = true
      await this.fetchTokenLocks({ tokenAddress: this.tokenAddress, lockHandler: this.lockHandler })
    } catch (e) {
      this.$alert.error(e.message)
    } finally {
      this.loading = false
    }
  },
  filters: {
    getAddress(accountAddr, account) {
      if (!account || !accountAddr) return ''
      return account === accountAddr.toLowerCase()
        ? 'Me'
        : accountAddr.substr(0, 6) + '...' + accountAddr.substr(accountAddr.length - 4)
    },
  },
}
</script>
<style scoped>
.v-menu__content {
  background-color: #202020 !important;
  color: #fff;
}
</style>
