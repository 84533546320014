<template>
  <v-dialog v-model="dialog" max-width="340px" persistent>
    <div class="white--text pa-6">
      <div class="d-flex justify-end">
        <v-icon @click="cancel">mdi-close</v-icon>
      </div>
      <div class="d-flex justify-center">
        <v-img max-width="90" contain :src="require(`@/assets/error.svg`)" />
      </div>
      <div class="mt-6 mb-2 errorDialog text-medium">Fail {{ titleText }} Token!</div>
      <div class="text-body-2 grey--text mb-6">
        Tokens aren't {{ contentText }}! Transaction on your wallet is fail. Please try later
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  props: {
    state: {
      default: false,
      type: Boolean,
    },
    contentText: String,
    titleText: String,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    cancel() {
      this.dialog = false
      this.$emit('closeError')
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = state
    },
  },
}
</script>
