<template>
  <v-dialog v-model="dialog" max-width="340px" persistent>
    <div class="pa-6 white--text">
      <div class="d-flex justify-end">
        <v-icon @click="cancel">mdi-close</v-icon>
      </div>
      <div class="d-flex justify-center">
        <v-img max-width="90" contain :src="require(`@/assets/success.svg`)" />
      </div>
      <div class="mt-6 mb-2 text-medium">Successful!</div>
      <div class="text-body-2 grey--text mb-6">
        Tokens are {{ contentText }}! You can check locks on your token page
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  props: {
    state: {
      default: false,
      type: Boolean,
    },
    contentText: String,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    cancel() {
      this.dialog = false
      this.$emit('closeSuccess')
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = state
    },
  },
}
</script>
