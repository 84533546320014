<template>
  <v-dialog v-model="dialog" max-width="571px" persistent>
    <div class="white--text" :class="$vuetify.breakpoint.smAndUp ? 'pa-6' : 'pa-2'">
      <div class="d-flex justify-space-between mb-5 text-medium">
        <h4 class="align-self-center">Withdraw Token</h4>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </div>
      <v-sheet rounded="xl" class="pa-6 secondary-lighten-3">
        <p class="text-caption text-right">withdrawable: {{ withdrawable }}</p>
        <v-form ref="withdrawForm">
          <v-row class="text-body-1">
            <v-col cols="12" sm="6" :order="$vuetify.breakpoint.smAndUp ? '1' : '2'" class="pb-0">
              <v-text-field
                outlined
                value="0.0"
                rounded
                dense
                v-model="withdrawAmount"
                :rules="[$rules.required, rules.maxToken, rules.minToken]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              :order="$vuetify.breakpoint.smAndUp ? '2' : '1'"
              class="d-flex align-center justify-end pt-0"
            >
              <v-img max-width="24" max-height="24" contain :src="require(`@/assets/binance.svg`)" />
              <span class="mx-3 text-h6">{{ userTokenInfo.name }}</span>
              <v-btn
                class="primary white--text"
                color="white"
                small
                rounded
                max-height="24"
                @click="onMultipleChange(1)"
                >MAX</v-btn
              >
            </v-col>
            <v-col cols="12" order="3" class="d-flex flex-wrap">
              <v-btn text class="text-body-1" @click="onMultipleChange(0.25)">25%</v-btn>
              <v-btn text class="text-body-1" @click="onMultipleChange(0.5)">50%</v-btn>
              <v-btn text class="text-body-1" @click="onMultipleChange(0.75)">75%</v-btn>
              <v-btn text class="text-body-1" @click="onMultipleChange(1)">100%</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
      <v-btn block class="primary mt-6" large rounded @click="onWithdraw" :loading="loading">Withdraw</v-btn>
    </div>
    <WaitingDialog :state="waitingState" contentText="withdraw" @closeWaiting="waitingState = false" />
    <SuccessDialog :state="successState" contentText="withdrawn" @closeSuccess="successState = false" />
    <ErrorDialog :state="errorState" titleText="withdrawing" contentText="withdrawn" @closeError="errorState = false" />
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { FIXEDNUMBER_0 } from '@/constants/'
import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'
import WaitingDialog from '@/components/dialogs/WaitingDialog'
import SuccessDialog from '@/components/dialogs/SuccessDialog'
import ErrorDialog from '@/components/dialogs/ErrorDialog'

export default {
  components: {
    WaitingDialog,
    SuccessDialog,
    ErrorDialog,
  },
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    tokenLock: Object,
  },
  data() {
    return {
      dialog: false,
      waitingState: false,
      successState: false,
      errorState: false,
      withdrawAmount: '0.0',
      withdrawable: FIXEDNUMBER_0,
      loading: false,
      rules: {
        maxToken: (v) => {
          if (!new RegExp(/^\d+(\.\d{1,18})?$/).test(v)) return 'Invalid value'
          else
            return (
              fixedNumberHelper.lte(fixedNumberHelper.from(v), this.withdrawable) ||
              `You do not have enough ${this.userTokenInfo.name} for this transaction.`
            )
        },
        minToken: (v) => {
          if (!new RegExp(/^\d+(\.\d{1,18})?$/).test(v)) return 'Invalid value'
          else return !fixedNumberHelper.from(v).isZero() || 'Invalid value'
        },
      },
    }
  },
  computed: {
    ...mapState('tokenLocks', ['userTokenInfo']),
    ...mapState('auth', ['lockHandler']),
  },
  methods: {
    onMultipleChange(multiple) {
      this.withdrawAmount = fixedNumberHelper.mulUnsafe(this.withdrawable, fixedNumberHelper.from(multiple)).toString()
    },
    async onWithdraw() {
      if (!this.$refs.withdrawForm.validate()) return
      try {
        this.loading = true
        this.waitingState = true
        await this.lockHandler.handleWithdraw(
          this.tokenLock.lockId,
          fixedNumberHelper.from(this.withdrawAmount),
          this.userTokenInfo.decimals
        )
        // this.$alert.success('withdrawn successfully')
        this.dialog = false
        this.successState = true
        this.waitingState = false
        this.$emit('withdrawDone')
      } catch (error) {
        console.error(error)
        // this.$alert.error(error.message)
        this.errorState = true
        this.waitingState = false
      } finally {
        this.loading = false
      }
    },
    async resetDefaut() {
      this.$refs.withdrawForm && this.$refs.withdrawForm.reset()
      this.waitingState = false
      this.withdrawAmount = '0.0'
      try {
        this.withdrawable = await this.lockHandler.getWithdrawableTokens(
          this.tokenLock.lockId,
          this.userTokenInfo.decimals
        )
      } catch (e) {
        console.error(e)
      }
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    async state(state) {
      this.dialog = true
      await this.resetDefaut()
    },
  },
}
</script>
