<template>
  <v-dialog v-model="dialog" max-width="571px" @keydown.esc="cancel">
    <div class="white--text text-start" :class="$vuetify.breakpoint.smAndUp ? 'pa-6' : 'pa-2'">
      <div class="d-flex justify-space-between mb-6">
        <div class="align-self-center text-medium">Relock Token</div>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </div>
      <div class="text-body-1">Current lock date:</div>
      <div class="text-medium">
        <span>{{ tokenLock.endEmission | normalizeTimeDuration }}</span> <span>&#183; </span>
        <span>{{ tokenLock.endEmission | fullTime }}</span>
      </div>
      <div class="mt-6 my-4 text-body-1">New unlockdate</div>
      <v-sheet rounded="xl" class="secondary-lighten-3 pa-6">
        <v-form ref="relockForm">
          <v-row>
            <v-col sm="6" cols="12" class="py-0">
              <p class="text-caption mb-2">Date</p>
              <v-text-field
                v-model="unlockDate"
                type="date"
                dense
                outlined
                rounded
                :rules="[$rules.required, $rules.date, rules.relockDate]"
              />
            </v-col>
            <v-col sm="6" cols="12" class="py-0">
              <p class="text-caption mb-2">Hour</p>
              <v-text-field v-model="unlockHour" type="time" dense outlined rounded :rules="[$rules.required]" />
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
      <div class="text-center mt-3 mb-6 text-caption">
        <span class="red--text" v-if="isInvalid">*</span>Lock date needs to be after current lock date
      </div>
      <div class="text-body-1">FEE: {{ tokenFeePercent.toString() }}%</div>
      <v-btn block class="primary my-6" large rounded @click="onRelock" :loading="loading">Relock</v-btn>
    </div>
    <WaitingDialog :state="waitingState" contentText="relock" @closeWaiting="waitingState = false" />
    <SuccessDialog :state="successState" contentText="relocked" @closeSuccess="successState = false" />
    <ErrorDialog :state="errorState" titleText="relocking" contentText="relocked" @closeError="errorState = false" />
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import WaitingDialog from '@/components/dialogs/WaitingDialog'
import SuccessDialog from '@/components/dialogs/SuccessDialog'
import ErrorDialog from '@/components/dialogs/ErrorDialog'

export default {
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    tokenLock: Object,
  },
  components: {
    WaitingDialog,
    SuccessDialog,
    ErrorDialog,
  },
  data() {
    return {
      dialog: false,
      waitingState: false,
      successState: false,
      errorState: false,
      isInvalid: true,
      unlockDate: '',
      unlockHour: '',
      loading: false,
      rules: {
        relockDate: (v) => {
          return (
            (!!v &&
              Date.parse(new Date(this.unlockDate + ' ' + this.unlockHour)) / 1000 > this.tokenLock.endEmission) ||
            'Invalid date'
          )
        },
      },
    }
  },
  computed: {
    ...mapState('tokenLocks', ['userTokenInfo', 'tokenFeePercent']),
    ...mapState('auth', ['lockHandler']),
  },
  methods: {
    onMultipleChange(multiple) {
      this.withdrawAmount = this.withdrawable * multiple
    },
    async onRelock() {
      if (!this.$refs.relockForm.validate()) return
      try {
        this.loading = true
        this.waitingState = true
        const unlockDate = Date.parse(new Date(this.unlockDate + ' ' + this.unlockHour)) / 1000
        await this.lockHandler.handleRelock(this.tokenLock.lockId, unlockDate)
        // this.$alert.success('Relock token successfully')
        this.dialog = false
        this.successState = true
        this.waitingState = false
        this.$emit('relockDone')
      } catch (error) {
        console.error(error)
        // this.$alert.error(error.message)
        this.errorState = true
        this.waitingState = false
      } finally {
        this.loading = false
      }
    },
    activeValidation() {
      this.$refs.relockForm.validate()
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    async state(state) {
      this.dialog = true
      this.$refs.relockForm && this.$refs.relockForm.reset()
    },
    unlockHour: 'activeValidation',
  },
}
</script>
