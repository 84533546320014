import { render, staticRenderFns } from "./TokenLocks.vue?vue&type=template&id=e222b258&scoped=true&"
import script from "./TokenLocks.vue?vue&type=script&lang=js&"
export * from "./TokenLocks.vue?vue&type=script&lang=js&"
import style0 from "./TokenLocks.vue?vue&type=style&index=0&id=e222b258&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e222b258",
  null
  
)

export default component.exports